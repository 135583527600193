import { instance } from '@/utils/http.js'

// 获取光大 sso 跳转地址
export const getSSOUrl = function (type) {
  if (['sso', 'review'].find(value => { return value == type })) {
    return instance({
      url: '/api/rpc/gd/'+type+'_url',
      method: 'GET',
      baseURL: process.env.NODE_ENV === 'development' ? 'http://10.10.11.250:705' : 'https://slbk.sunrayai.cn',
      params: {
        user_id: JSON.parse(window.localStorage.getItem('userInfo')).edu_user_id,
        platform:2
      },
      // sign:'A719n7UkTikur*6_@MhJ'
    })
  }
}
